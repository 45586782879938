Vue.component('home', {
    props: [],
    data: function() {
        return {
            invoices: [],
            invoicesNext: []
        }
    },

    activate: function(done) {
        var self = this;

        $.get('/api/invoices', function(data) {
            self.invoices = data;
        });

        $.get('/api/invoicesNext', function(data) {
            self.invoicesNext = data;
        });

        done();
    },
    methods: {
        refresh: function(client, event) {
            var self = this;
            
            $(event.target).children('.fa').addClass('fa-spin');

            $.get('/api/refresh/' + client.service_id, function(data) {
                if (data.id) {
                    self.invoicesNext.push(client);
                    self.invoices.$remove(client);
                }
            });
        }
    }
});

