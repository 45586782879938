Vue.component('refresh-date', {

    props: ['service'],

    template: '',

    methods: {
        refresh: function() {
            var self = this;

            $.get('/api/refresh/' + this.service.id, function(data) {
                this.$dispatch('remove-item', self.service);
            });
        }
    }

});