Vue.component('price-table', {
    props: ['service_id'],

    data: function () {
        return {
            prices: []
        };
    },

    activate: function(done) {
        var self = this;

        $.get('/api/price', { service_id: this.service_id }, function (data) {
            self.prices = data;

            done();
        });
    },

    methods: {
        addPrice: function() {
            this.prices.push({
                id: 0,
                service_id: this.service_id,
                name: '',
                price: 0,
                frequency: 'annually',
                notes: ''
            });
        },

        removePrice: function(price) {
            this.prices.$remove(price);
        }
    }
});