Vue.component('service-prices', {

    props: ['service_id', 'selected_id'],

    data: function () {
        return {
            prices: [{
                id: 0,
                name: 'select a service'
            }]
        };
    },

    template: '<select name="price_id" v-model="selected_id"><option value="{{ price.id }}" v-for="price in prices">{{ price.name }}</option></select>',

    watch: {
        'service_id': function (val, oldVal) {
            this.updateList(val);
        }
    },

    methods: {

        updateList: function(id) {
            var self = this;

            self.prices = [{
                id: 0,
                name: 'loading..'
            }];

            $.get('/api/price', { service_id: id }, function (data) {
                data.unshift({
                    id: 0,
                    name: 'select a price'
                });
                self.prices = data;
            });

        }
    }

});